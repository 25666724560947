.accounting-contacts {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem;
}

.accounting-filter-nav-header-without {
    display: flex;
    justify-content: space-between;
}

.document-wrapper {
    background-color: rgba(255, 255, 255, 0.548);
    width: 100% !important;
    border-radius: 20px;
}

.ant-card-head-title {
    color: #f07d00;
    font-weight: 700;
    font-size: 22px;
}

.ds-card-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
    gap: 2rem;
}

.card-main__each {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1.5rem;
    padding: 1.5rem 1rem;
    padding-bottom: 3.5rem;
    border-radius: 20px;
    width: 500px;
    background-color: rgba(255, 255, 255, 0.452);
    color: var(--grey-color);
    box-shadow: var(--shadow-1), var(--shadow-2);
    transition: 0.3s scale, 0.3s background-color, 0.3s color, 0.3s border-radius,
        0.3s box-shadow;
    overflow: hidden;
}

.card-main__each:hover {
    scale: 1.05;
    /* background-color: var(--orange-color); */
    /* color: #fff; */
    box-shadow: var(--shadow-1), var(--shadow-2);
}

.card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: 80px;
    height: 80px;
    padding: 0.2rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.icon-color {
    color: #f07d00;
}

.icon-display {
    display: flex;
    gap: 4rem;
}

.title {
    color: #f07d00;
    font-size: 1.5rem;
    font-weight: 600;
}