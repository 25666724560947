.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}

.header-image {
  padding-top: 1rem;
  width: 160px;
  height: 50px;
  animation: slide-in-left 0.8s;
  cursor: pointer;
}

.header-log-out-btn {
  display: flex;
  align-items: center;
  animation: zoom-in 0.8s;
  font-weight: 600;
}

.header-log-out-btn:after {
  content: "";
  position: absolute;
  font-weight: 600;
  opacity: 0;
  transition: 0.5s;
}

.header-log-out-btn:hover {
  padding-right: 64px;
  padding-left: 8px;
}

.header-log-out-btn:hover:after {
  content: "Log Off";
  opacity: 1;
  right: 10px;
}
